/* NAVBAR */
.navbar{
    box-shadow: 0px 1px 5px 0px rgba(219,219,219,1);
    background-color:white;
}
.nav-link{
    text-transform:uppercase;
    font-size: 0.875rem;
    font-weight:bold;
    padding-top:0.4rem;
    padding-bottom:0.4rem;
}
.navbar-toggler{
    margin-top: 6px;
}
#navbarCollapse{
    .nav-link{
    padding-right: 2rem;
    padding-left: 2rem;
    margin-left: .6rem;
    margin-right: .6rem;
        &:not(.active){
            &:active{
                color:white;
            }
            &:focus{
                color:$orange;
                outline:none;
                box-shadow:none;
            }
        }
    }
}
@media (max-width: 1450px) {
    #navbarCollapse{
        .nav-link{
            padding-right: .85rem;
            padding-left: .85rem;
            margin-left: .45rem;
            margin-right: .45rem;
        }
    }
}
@media (max-width: 1360px) {
    #navbarCollapse{
        .nav-link{
            padding-right: .85rem;
            padding-left: .85rem;
            margin-left: .2rem;
            margin-right: .2rem;
            font-size: 0.75rem;
        }
    }
}
@media (max-width: 1199px) {
    #navbarCollapse{
        .nav-link{
            border:none;
            border-bottom:1px solid;
            text-align:right;
            border-radius:0;
            &.active{
                background-color:transparent;
                color:$orange;
            }
        }
    }
}
@media (max-width: 575px) {
    .navbar-brand{
        img{
            width:140px;
        }
    }
    .navbar-toggler{
        margin-top:0px;
    }
}