/* KNOTS */

.knot{
    position:absolute;
    width:16px;
    height:16px;
    background-color:$blue;
    &--center{
        width: 48px;
    }
}