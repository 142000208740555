@import '../libs/bootstrap-4/scss/bootstrap.scss';
@import 'components/colors';

body{
    font-family:Verdana,Geneva,sans-serif;
    padding-top: 4.5rem;
    color:$blue;
    position:relative;
}
.btn{
    border-radius:1rem;
    text-transform: uppercase;
}
.section-headline,
%section-headline{
    font-size:1.75rem;
    font-weight:bold;
    margin-bottom:1.6rem;
}
.section-padding,
%section-padding{
    padding:5rem 0;
}
.section-button,
%section-button{
    min-width:14.75rem;
}
.relative{
    position:relative;
}

@import 'components/navbar';
@import 'components/about';
@import 'components/knots';
@import 'components/how-it-works';
@import 'components/want-it';
@import 'components/reference';
@import 'components/contact';
@import 'components/applications';
@import 'components/download';
@import 'components/app-footer';
@import 'components/alerts';