/* APPLICATIONS */

#applications-page{
    padding-top:2rem;
    h1{
        @extend .section-headline;
    }
    h2{
        font-size:1.375rem;
        text-transform:uppercase;
        font-weight:bold;
        margin-bottom:2rem;
    }
    p{
        font-size: 0.875rem;
    }
    section{
        @extend .section-padding;
    }
    .bg-blue{
        background-color:$blue;
        color:white;
    }
    .img-wrapper{
        img{
            margin-right:1.2rem;
            margin-top:1.2rem;
        }
    }
    .rounded{
        border-radius:1rem;
    }
}

@media (max-width: 767px) {
    #applications-page{
        .media{
            flex-direction:column;
            text-align:center;
            &>img{
                margin:0 auto !important;
                order:-1;
            }
        }
        .media-body{
            margin-top:2rem;
        }
        .img-wrapper{
            img{
                margin-right:0;
            }
        }
        .gallery{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}

@media (max-width: 440px) {
    #applications-page{
        .gallery{
            flex-direction:column;
        }
        .img-wrapper{
            display:flex;
            justify-content: center;
        }
    }
}