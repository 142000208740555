/* REFERENCE SECTION */

#reference{
    @extend .section-padding;
    text-align:center;
    background-color:$want-it-bg;
    h1{
        @extend .section-headline;
    }
    .logos{
        margin-top: 3.125rem;
            img{
                margin:1.5625rem 0;
            }
    }
}