/* CONTACT US SECTION */

#contact-us{
    @extend .section-padding;
    text-align:center;
    background-color:$blue;
    color:white;
    h1{
        @extend .section-headline;
    }
    textarea{
        resize:none;
    }
    .addresses{
        text-align:left;
    }
    h2{
        font-size:1.125rem;
    }
    h3{
        font-size:1rem;
        font-weight:bold;
        margin-top:1.125rem;
        margin-bottom:0.25rem;
    }
    .second{
        margin-top: 3.125rem;
        p{
            margin:0;
            font-size:0.875rem;
            line-height:1.2;
        }
        .smaller{
            font-size:0.75rem;
            text-align:left;
        }
    }
    .form-control{
        border-radius:10px;
        padding:0.625rem 1.5625rem;
    }
    .btn-info{
        margin-top: 3.125rem;
        @extend .section-button;
        cursor:pointer;
    }
    .addresses{
        a{
            color:white;
        }
    }
}

@media (max-width: 767px){
    #contact-us{
        .addresses{
            order: -1;
            margin-bottom: 3.125rem;
            text-align:center;
        }
    }
}