/* HOW IT WORKS SECTION */

#how-it-works{
    @extend .section-padding;
    background-color:$blue;
    color:white;
    text-align:center;
    h1,h2{
        @extend .section-headline;
    }
    p{
        font-size:1.125rem;
        margin-bottom:0.8rem;
    }
    .smaller{
        font-size:0.875rem;
    }
    .btn-info{
        @extend .section-button;
    }
    .labels{
        position:absolute;
        width:100%;
        bottom: 3.125rem;
        font-size:0.875rem;
        font-weight:bold;
    }
    .label-xs{
        font-size:0.875rem;
        font-weight:bold;
        &--top{
            position:relative;
            top:3rem;
        }
    }
}

.hiw-img{
    margin: 5rem 0 5rem;
}

#applications{
    margin:7.5rem 0;
    .nav-item{
        padding: 0 1.25rem;
    }
    img{
        margin-bottom:1.5625rem;
    }
    h3{
        font-size:0.875rem;
        font-weight:bold;
        margin-bottom:2.1875rem;
    }
    p{
        font-size:0.75rem;
        text-align:center;
    }
}

#aplikace{
    padding-top: 5rem;
}

@media (min-width: 1200px){
    #how-it-works{
        .container-fluid {
            width: 1140px;
            max-width: 100%;
        }
    }
}

@media (max-width: 767px){
    #applications{
        margin:4.5rem 0;
        .nav-item{
            margin-bottom:2.1875rem;
        }
    }
}