/* ABOUT SECTION */

#about{
    padding-top: 5rem;
    h1{
        font-size: 1.375rem;
        font-weight:bold;
        margin-top:1.125rem;
        margin-bottom:1.25rem;
    }
    .btn{
        @extend .section-button;
        position:relative;
        &:after{
            content: url('../images/arrow-down.png');
            //font-family: FontAwesome;
            //font-size:1.5rem;
            position:absolute;
            width:65px;
            bottom:-2.15rem;
            left:50%;
            margin-left:-32.5px;
            padding:7px 0;
            color:white;
            background-color:$blue;
            border-bottom-right-radius: 2rem;
            border-bottom-left-radius: 2rem;
        }
    }
    .hero-text{
        margin-bottom:1.875rem;
    }
}
.about{
    &--btn{
        background-color: $blue;
        color:white;
    }
}
.clouds{
    position:relative;
    overflow:hidden;
    margin-top:5rem;
    padding-bottom:5rem;
    z-index:5;
    &--left{
        position:absolute;
        transform: translate(-20%,75%);
        left:0;
    }
    &--center{
        display:block;
    }
    &--right{
        position:absolute;
        transform: translate(20%,75%);
        right:0;
    }
}
@import 'timeline';
@import 'buildings';