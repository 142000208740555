.alert{
    position: fixed;
    top: 69px;
    width: 100%;
    right: 0;
    z-index: 1200;
    font-size: 0.875rem;
    padding: .375rem 1rem;
    border-radius: 0;
    text-align: center;
    &-success{
        color: white;
        background-color: $orange;
        border-color: darken($orange, 10);
    }
    &-danger{
        color: white;
        background-color: $red;
        border-color: darken($red, 10);
    }
}
@media (max-width: 575px) {
    .alert{
        top: 58px;
    }
}
@media (max-width: 420px) {
    .alert{
        font-size: 0.8125rem;
    }
}