/* Timeline*/

.timeline{
    list-style-type: none;
    position:relative;
    padding-left:0;
    padding-bottom:7rem;
    margin-bottom:0;
    &>li{
        margin-bottom:0px;
        position:relative;
        margin-top:-4px;
        &:before, &:after{
            content: " ";
            display: table;
        }
        &:after{
            clear: both;
        }
        &:not(.inverted){
            padding-right:90px;
        }
    }
    &--panel{
        width:50%;
        float:left;
        position:relative;
        text-align: right;
        &:after{
            content: '';
            background: url('../images/circle.svg');
            position: absolute;
            height: 10px;
            width: 10px;
            right: -26px;
            background-size: 10px 10px;
            background-repeat: no-repeat;
            top: 50%;
            margin-top: -3.5px;
        }
    }
    &--heading{
        font-size:1.125rem;
        font-weight:bold;
    }
    p{
        font-size:0.875rem;
        margin-bottom:0;
    }
}
.inverted{
    padding-left:90px;
    .timeline--panel{
        float:right;
        text-align:left;
        &:after{
            right: auto;
            left: -26px;
        }
    }
}
.vertical-line{
    position:absolute;
    left: calc(50% - 2px);
    width:4px;
    background-color:$blue;
}
.horizontal-line{
    position:absolute;
    height:4px;
    background-color:$blue;
    width:600px;
    top:0;
}

@media (max-width: 767px){
    .timeline{
        &--panel{
            width:100%;
            text-align:center;
            margin-bottom:2.1875rem;
            &:after{
                display: none;
            }
        }
        &>li{
            padding:0 !important;
        }
    }
    .inverted{
        .timeline--panel{
            text-align:center;
        }
    }
}