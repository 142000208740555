/* DOWNLOAD SECTION */

#download{
    ul{
        margin-top:3rem;
        text-align:center;
    }
    a{
        color:white;
        &:hover{
            color:$orange;
        }
    }
}