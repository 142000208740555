/* WANT QTCLOUD SECTION */

#want-qtcloud{
    text-align:center;
    @extend .section-padding;
    background-color: $want-it-bg;
    h1{
        @extend .section-headline;
    }
    .smaller{
        font-size:0.875rem;
    }
    .btn{
        @extend .section-button;
    }
}