/* BUILDINGS */
.line,
%line{
    position:absolute;
    width:4px;
    background-color:$blue;
    left:calc(50% - 2px);
    bottom:0;
}
.buildings{
    position:relative;
    margin-top:8rem;
    &--left{
        display: block;
    }
    &--center{
        display:block;
    }
    &--right{
        display:block;    
        margin-right: -15px;
        margin-left: auto;
    }
    .col{
        &--left{
            .line{
                @extend .line;
            }
        }
        &--right{
            .line{
                @extend .line;
            }
        }
    }
}
@media (max-width: 767px){
    .buildings{
        margin-top:0;
        &--right{
            margin-right: auto;
        }
    }
}